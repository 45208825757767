<template>
  <div>
    <History></History>
  </div>
</template>
<script>

import History from "@/components/public/History/index.vue"

export default {
  data() {
    return {};
  },

  components: {
    History,
  },

};
</script>